var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeTooltip),expression:"closeTooltip"}],staticClass:"tooltip-up-balance",class:{
    'tooltip-up-balance_position_bottom': _vm.tooltipPosition === 'bottom',
  }},[_c('span',{staticClass:"tooltip-up-balance__close",on:{"click":function($event){return _vm.$emit('close')}}}),_c('div',{staticClass:"tooltip-up-balance__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.description)?_c('div',{staticClass:"tooltip-up-balance__description"},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e(),_c('div',{staticClass:"tooltip-up-balance__amount"},[_c('MainSelect',{attrs:{"selected-option":_vm.amount,"options":_vm.REFILL_AMOUNTS,"style-type":"background-gray"},on:{"update:selectedOption":function($event){_vm.amount=$event},"update:selected-option":function($event){_vm.amount=$event}}})],1),_c('div',{staticClass:"tooltip-up-balance__payment-method payment-method"},[_c('p',[_vm._v("Способ пополнения")]),_c('button',{staticClass:"payment-method__button",class:[
        { 'payment-method__button_active': _vm.paymentMethod === 'sbp' },
        'payment-method__button_sbp',
      ],attrs:{"type":"button"},on:{"click":function($event){_vm.paymentMethod = 'sbp'}}},[_c('img',{attrs:{"src":require("../../assets/pay-systems/sbp.svg"),"alt":"sbp"}}),_vm._v(" СБП "),_c('span',{staticClass:"payment-method__label"},[_vm._v("Рекомендуем")])]),_c('button',{staticClass:"payment-method__button",class:{ 'payment-method__button_active': _vm.paymentMethod === 'card' },attrs:{"type":"button"},on:{"click":function($event){_vm.paymentMethod = 'card'}}},[_c('img',{attrs:{"src":require("../../assets/pay-systems/card.svg"),"alt":"card"}}),_vm._v(" Банковской картой ")])]),(_vm.sberbankRefill)?_c('WarningPopup',{staticClass:"tooltip-up-balance__warning",attrs:{"content":_vm.sberbankRefill.content}}):_vm._e(),_c('div',{staticClass:"tooltip-up-balance__button"},[_c('MainButton',{attrs:{"title":_vm.buttonText,"color":"gold","disabled":_vm.isDisabledButton || _vm.isDisableRefillButton},on:{"click":function($event){return _vm.refillBalanceRequest(_vm.amount.id)}}}),(_vm.isDisableRefillButton)?_c('p',{staticClass:"tooltip-up-balance__limit-error"},[_vm._v(" Превышен лимит ")]):_vm._e(),_c('div',{staticClass:"tooltip-up-balance__yandex-pay",attrs:{"id":"yandex-pay"}})],1),_vm._m(0),_c('div',{staticClass:"tooltip-up-balance__agreement"},[_c('p',[_vm._v(" Нажимая кнопку «Перейти к оплате», вы принимаете "),_c('a',{attrs:{"href":_vm.userAgreement,"download":""}},[_vm._v("Пользовательское соглашение")]),_vm._v(", "),_c('a',{attrs:{"href":_vm.privacyPolicy,"download":""}},[_vm._v("Политику конфиденциальности")]),_vm._v(", "),_c('a',{attrs:{"href":_vm.licenseAgreement,"download":""}},[_vm._v("Лицензионное соглашение")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tooltip-up-balance__systems"},[_c('img',{attrs:{"src":require("../../assets/pay-systems/visa.svg"),"alt":"visa"}}),_c('img',{attrs:{"src":require("../../assets/pay-systems/mastercard.svg"),"alt":"mastercard"}}),_c('img',{attrs:{"src":require("../../assets/pay-systems/mir.svg"),"alt":"mir"}})])
}]

export { render, staticRenderFns }