<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import MainTitleBack from "@/components/helpers/MainTitleBack";
import TariffPreview from "@/components/tariff/TariffPreview";
import MainButton from "@/components/helpers/MainButton";
import MainCheckbox from "@/components/helpers/MainCheckbox.vue";
import TariffBuyCertificates from "@/components/tariff/TariffBuyCertificates.vue";
import TariffBuyTotalPrice from "@/components/tariff/TariffBuyTotalPrice.vue";
import MainSelect from "@/components/helpers/MainSelect.vue";

export default {
  name: "TariffBuy",

  components: {
    MainSelect,
    TariffBuyTotalPrice,
    TariffBuyCertificates,
    MainTitleBack,
    TariffPreview,
    MainButton,
    MainCheckbox,
  },

  data() {
    return {
      buyingTariffStatus: "",
      agreementUrl: "",
      agreement: false,
      distribAgreement: false,
      distribAgreementUrl: "",
      // distributorAgreement: false,
      selectedCity: null,
      selectedRepresentation: null,
      isShowPopper: false,
      nonRefundablePayments: false,
    };
  },

  watch: {
    selectedCity: function () {
      if (this.selectedCity) {
        this.selectedRepresentation = null;
        this.loadRepresentations(this.selectedCity.name);
      }
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      tariff: (state) => state.tariff.tariff,
      cities: (state) => state.representation.cities,
      representations: (state) => state.representation.representations,
      previewTariffPrice: (state) => state.tariff.previewTariffPrice,
    }),

    ...mapGetters({
      getUserDistributorAccount: "auth/getUserDistributorAccount",
    }),

    isDisabledButtonBuy() {
      const certificatesAmount =
        this.previewTariffPrice?.certificates?.reduce((total, currentValue) => {
          return total + +currentValue.amount;
        }, 0) ?? 0;
      if (
        certificatesAmount + this.getUserDistributorAccount?.summary_active <
        this.tariff?.user_price
      ) {
        return true;
      }

      return (
        ((!this.selectedCity || !this.selectedRepresentation) &&
          !this.user.representation) ||
        !this.agreement ||
        !this.distribAgreement ||
        !this.nonRefundablePayments
      );

      // Относится к покупке тарифа с сертификатами
      // || !this.distributorAgreement
    },

    isShowButtonBuyCertificate() {
      const PERMITTED_TARIFFS = [
        "business_d",
        "premium_d",
        "vip_d",
        "vip_gold_d",
      ];

      return PERMITTED_TARIFFS.includes(this.user?.tariff?.code);
    },
  },

  methods: {
    ...mapMutations({
      setPreviewTariffPrice: "tariff/SET_PREVIEW_TARIFF_PRICE",
    }),

    ...mapActions({
      loadTariff: "tariff/loadTariff",
      buyTariff: "tariff/buyTariff",
      loadCities: "representation/loadCities",
      loadRepresentations: "representation/loadRepresentations",
      storeRepresentation: "representation/storeRepresentation",
      checkUser: "auth/checkUser",
      loadDocuments: "loadDocuments",
      createNotification: "createNotification",
    }),

    showPopper() {
      const certificatesAmount =
        this.previewTariffPrice?.certificates?.reduce((total, currentValue) => {
          return total + +currentValue.amount;
        }, 0) ?? 0;

      if (
        this.getUserDistributorAccount?.summary_active + certificatesAmount <
        this.tariff?.user_price
      ) {
        this.isShowPopper = true;
      }
    },

    buyTariffRequest(tariffId) {
      this.buyingTariffStatus = "LOADING";

      if (this.user.representation) {
        this.buyTariff({
          tariffId: tariffId,
          certificates:
            this.$refs.tariffBuyCertificates?.formattedCertificates ?? [],
        }).then(() => {
          this.checkUser();

          this.buyingTariffStatus = "SUCCESS";
        });
      } else {
        this.storeRepresentation(this.selectedRepresentation.id).then(() => {
          this.buyTariff({
            tariffId: tariffId,
            certificates:
              this.$refs.tariffBuyCertificates?.formattedCertificates ?? [],
          }).then(() => {
            this.checkUser();

            this.buyingTariffStatus = "SUCCESS";
          });
        });
      }
    },
  },

  beforeMount() {
    this.setPreviewTariffPrice(null);

    if (!this.$route.params?.tariffId) {
      this.$router.push({ name: "Tariffs" });
    } else {
      this.loadTariff(this.$route.params.tariffId);
      this.loadCities();
      this.loadDocuments({ page: "tariff" }).then((response) => {
        // this.agreementUrl = response.data?.agreement[0]?.url;
      });
    }
  },

  mounted() {
    // this.distribAgreementUrl = `${process.env.VUE_APP_FRONT_PANEL_URL}docs/Дистрибьюторское_соглашение.pdf`;
    this.distribAgreementUrl = `${process.env.VUE_APP_FRONT_PANEL_URL}docs/дистриб согл РК 25.12.24.docx`;
    this.agreementUrl = `${process.env.VUE_APP_FRONT_PANEL_URL}docs/лиценз договор РК 25.12.24.docx`;
  },
};
</script>

<template>
  <div class="tariffs-buy default-content-padding">
    <div class="tariffs-buy__wrapper">
      <MainTitleBack title="Покупка тарифа" href="Tariffs" />

      <div v-if="tariff" class="tariffs-buy__preview">
        <TariffPreview :tariff="tariff" />
      </div>

      <template v-if="buyingTariffStatus !== 'SUCCESS'">
        <template v-if="!user.representation">
          <div class="tariffs-buy__representation representation">
            <h2>Адрес представительства</h2>

            <p>
              Выбирая представительство, вы прикрепляетесь к офису
              дистрибьюторов в своем регионе. Благодаря этому мы собираем
              статистику роста дистрибьюции
            </p>

            <span class="divider" />

            <div class="representation__selectors">
              <MainSelect
                :selected-option.sync="selectedCity"
                :options="cities"
                :title="'Ваш город'"
                :style-type="'background-gray'"
                :is-set-default="false"
              />

              <MainSelect
                v-if="selectedCity"
                :selected-option.sync="selectedRepresentation"
                :options="representations"
                :title="'Адрес'"
                :style-type="'background-gray'"
                :is-set-default="false"
              />
            </div>
          </div>
        </template>

        <TariffBuyCertificates
          v-if="tariff && false"
          ref="tariffBuyCertificates"
          class="tariffs-buy__certificates"
          :tariff-id="+$route.params.tariffId"
        />

        <TariffBuyTotalPrice
          v-if="
            previewTariffPrice &&
            previewTariffPrice?.certificates?.length &&
            false
          "
          :tariff="tariff"
          :price-info="previewTariffPrice"
        />

        <div class="tariffs-buy__checkboxes">
          <MainCheckbox
            :id="'agreement'"
            v-model="agreement"
            class="tariffs-buy__checkbox"
            :model-value="agreement"
            :title="'Я принимаю условия '"
            :link-text="'лицензионного соглашения'"
            :href="agreementUrl"
          />

          <MainCheckbox
            :id="'distribAgreement'"
            v-model="distribAgreement"
            class="tariffs-buy__checkbox"
            :model-value="distribAgreement"
            :title="'Я принимаю условия '"
            :link-text="'дистрибьюторского соглашения'"
            :href="distribAgreementUrl"
          />

          <MainCheckbox
            id="non-refundable-payments"
            v-model="nonRefundablePayments"
            class="tariffs-buy__checkbox"
            :model-value="nonRefundablePayments"
            title="Я ознакомлен с тем, что, согласно пункту 4.8 Лицензионного соглашения, лицензионные платежи не подлежат перерасчету или возврату"
          />

          <!-- <MainCheckbox
            :id="'distributor-agreement'"
            v-model="distributorAgreement"
            class="tariffs-buy__checkbox"
            :model-value="distributorAgreement"
            :title="'Я ознакомлен с '"
            :link-text="'правилами покупки и активации сертификата'"
            :href="'./docs/ПРАВИЛА_ПРИОБРЕТЕНИЯ_И_ПРОДАЖИ_ЭЛЕКТРОННЫХ_ПОДАРОЧНЫХ_СЕРТИФИКАТОВ.pdf'"
          /> -->
        </div>

        <div class="tariffs-buy__button-wrapper">
          <VDropdown
            popper-class="tariffs-buy__dropdown"
            :triggers="[]"
            :distance="12"
            :placement="'auto'"
            :shown.sync="isShowPopper"
            @mouseenter.native="showPopper"
          >
            <MainButton
              class="tariffs-buy__button"
              title="Оплатить"
              :color="'dark'"
              :disabled="isDisabledButtonBuy"
              :loading="buyingTariffStatus === 'LOADING'"
              @click="buyTariffRequest(tariff.id)"
            />

            <template #popper>
              <div class="tariffs-buy__popper">
                <h4>Недостаточно средств</h4>
                <p>
                  На дистрибьюторском счете недостаточно средств, необходимо
                  пополнить счет или добавить сертификат(-ы)
                </p>

                <div class="tariffs-buy__popper-buttons">
                  <MainButton
                    :title="'Пополнить баланс'"
                    :color="'gold'"
                    @click="$router.push({ name: 'Balance' })"
                  />
                  <MainButton
                    v-if="isShowButtonBuyCertificate && false"
                    :title="'Купить сертификат'"
                    :color="'dark'"
                    @click="$router.push({ name: 'Certificates' })"
                  />
                </div>
              </div>
            </template>
          </VDropdown>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tariffs-buy {
  min-height: 100%;
  background-color: $background-white;
  padding-top: 48px;
  padding-bottom: 48px;

  &__wrapper {
    max-width: 600px;
  }

  &__preview {
    margin: 0 0 $space-xxl;
  }

  .representation {
    margin-bottom: 32px;

    > h2 {
      @include body-1-bold;
      color: $dark-primary;
      margin-bottom: 8px;
    }

    > p {
      @include text-2;
      color: $dark-fifth;
    }

    > .divider {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    &__selectors {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  &__certificates {
    margin-bottom: 32px;
  }

  &__checkboxes {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    z-index: 100;
  }

  &__button {
    max-width: 160px;
    width: 160px;
  }

  &__checkbox {
    &::v-deep {
      span {
        max-width: 400px !important;
      }
    }
  }
}

@media (max-width: 900px) {
  .tariffs-buy {
    padding-top: 40px;
    padding-bottom: calc(40px + 48px + 24px);

    &__wrapper {
      max-width: none;
    }

    &__button-wrapper {
      position: fixed;
      bottom: 24px;
      width: 100%;
      display: block;
      max-width: calc(100% - 96px);
    }

    &__button {
      width: 100%;
      max-width: none;
    }
  }
}

@media (max-width: 576px) {
  .tariffs-buy {
    &__button-wrapper {
      max-width: calc(100% - 64px);
    }
  }
}

@media (max-width: 425px) {
  .tariffs-buy {
    &__button-wrapper {
      max-width: calc(100% - 48px);
    }
  }
}

@media (max-width: 375px) {
  .tariffs-buy {
    &__button-wrapper {
      max-width: calc(100% - 32px);
    }
  }
}
</style>
